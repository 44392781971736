import { calculateTableKey, eachTableHeader } from './helpers'

export default function createSwapColumn(table) {
  if (!table) {
    console.error(`Table with ID "${table.id}" not found!`)
    return
  }
  const tableKey = calculateTableKey(table)

  reorderTable(table, tableKey)
  makeColumnsSwappable(table, tableKey)
}

function saveColumnOrder(table, tableKey) {
  let columns = JSON.parse(localStorage.getItem(tableKey)) || {}

  eachTableHeader(table, function (el, index) {
    columns[el.dataset.name] = { column: el.dataset.name, index }
  })

  window.localStorageObjectStore.set(tableKey, columns)
}

function reorderTable(table, tableKey) {
  const thead = table.querySelector('thead')
  const tbody = table.querySelector('tbody')
  const jsonConfig = JSON.parse(localStorage.getItem(tableKey))

  if (!jsonConfig) {
    return
  }

  const columnsOrder = Object.keys(jsonConfig)
    .map((key) => {
      return { column: jsonConfig[key].column, index: jsonConfig[key].index }
    })
    .sort((a, b) => a.index - b.index)

  const headerCells = Array.from(thead.rows[0].cells)
  const newHeaderCells = columnsOrder.map((col) => {
    return headerCells.find((cell) => cell.dataset.name === col.column)
  })

  thead.rows[0].innerHTML = ''
  newHeaderCells.forEach((cell) => {
    thead.rows[0].appendChild(cell)
  })

  const rows = Array.from(tbody.rows)
  rows.forEach((row) => {
    const cells = Array.from(row.cells)
    const newCells = columnsOrder.map((col) => {
      const index = headerCells.findIndex(
        (cell) => cell.dataset.name === col.column
      )
      return cells[index]
    })

    row.innerHTML = ''
    newCells.forEach((cell) => {
      row.appendChild(cell)
    })
  })
}

function makeColumnsSwappable(table, tableKey) {
  const columnsContainer = table.querySelector('thead tr')
  const elementsToPatch = table.querySelectorAll('tbody tr') || []

  columnsContainer.addEventListener('pointerdown', (e) => {
    let lastCursorX = e.clientX
    let columnElements = [...columnsContainer.querySelectorAll('th')]
    const firstTarget = e.target.closest('th')
    let firstTargetIndex = columnElements.indexOf(firstTarget)

    function preventDefault(e) {
      e.preventDefault()
    }

    document.addEventListener('selectstart', preventDefault)

    if (firstTargetIndex === -1) return

    function handleMove(e) {
      const newCursorX = e.clientX
      const secondTarget = e.target.closest('th')
      const secondTargetIndex = columnElements.indexOf(secondTarget)

      if (secondTargetIndex === -1) return
      if (firstTarget === secondTarget) return

      const isMoveToLeft = newCursorX < lastCursorX
      const isMoveToRight = newCursorX > lastCursorX
      lastCursorX = newCursorX

      const swapColumnInfo = {
        firstTargetIndex,
        secondTargetIndex,
        isMoveToLeft,
        isMoveToRight,
      }

      swapColumns(columnsContainer, swapColumnInfo)

      elementsToPatch.forEach((row) => {
        swapColumns(row, swapColumnInfo)
      })

      columnElements = [...columnsContainer.querySelectorAll('th')]
      firstTargetIndex = columnElements.indexOf(firstTarget)

      saveColumnOrder(table, tableKey)
    }

    function swapColumns(
      container,
      { firstTargetIndex, secondTargetIndex, isMoveToLeft, isMoveToRight }
    ) {
      const columns = Array.from(container.children)
      const firstTarget = columns[firstTargetIndex]
      const secondTarget = columns[secondTargetIndex]

      if (isMoveToLeft) {
        secondTarget.insertAdjacentElement('beforebegin', firstTarget)
      } else if (isMoveToRight) {
        secondTarget.insertAdjacentElement('afterend', firstTarget)
      }

      columns.splice(firstTargetIndex, 1)
      columns.splice(secondTargetIndex, 0, firstTarget)
    }

    columnsContainer.addEventListener('pointermove', handleMove)

    document.addEventListener(
      'pointerup',
      () => {
        columnsContainer.removeEventListener('pointermove', handleMove)
        document.removeEventListener('selectstart', preventDefault)
      },
      {
        once: true,
      }
    )
  })
}
