const { set, last } = require("lodash");

;(function () {
  const phonesInputApp = function (phones) {
    this.phones = phones

    return {
      phones,

      init: function ($watch) {
        const __this = this

        $watch('phones', () => { __this.autofocusLastPhone() }, { deep: true })
        __this.autofocusLastPhone()
      },

      autofocusLastPhone: function () {
        setTimeout(() => {
          const lastInput = document.querySelector('ul.phones-inputs > li:last-child .phone-input')

          if (lastInput && lastInput.value === '') {
            lastInput.focus()
          }
        }, 100)
      },

      addPhone: function () {
        this.phones.push({ id: null, phone_number: null, phone_type: null, position: null })
      },

      removePhone: function (index) {
        if (this.phones[index].id) {
          this.phones[index]._destroy = 1
        } else {
          this.phones.splice(index, 1)
        }
      },

      addPhoneMask: function (input) {
        Inputmask({ mask: '(999) 999-9999' }).mask(input)
        input.select()
      },

      checkPhonesPresence: function () {
        return this.phones.filter((phone) => phone._destroy !== 1).length > 0
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.phonesInputApp = phonesInputApp
  }

return phonesInputApp

})();
